<template>
  <div class="pay_container">
    <div>支&nbsp;付&nbsp;中...</div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Pay",
  data () {
    return {
      outTradeNo: "",
      name: "",
      deptName: "",
      inDate: "",
      outDate: "",
      cost: "",
      count: 0,
      isPay: "",
      patCardNo: ""
    };
  },
  created () {
    this.name = this.$route.query.name;
    this.deptName = this.$route.query.deptName;
    this.inDate = this.$route.query.inDate;
    this.outDate = this.$route.query.outDate;
    this.cost = this.$route.query.cost;
    this.patCardNo = this.$route.query.patCardNo;
    this.outTradeNo = this.$route.query.outTradeNo;
    this.getPayStatus();
  },
  methods: {
    //查询支付状态
    async getPayStatus () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        outTradeNo: this.outTradeNo
      });
      const { data: res } = await formPost(
        "/wxapp/pay/orderRecord/getOrderPayStatus",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        if (this.count < 3) {
          if (res.data.isPay === 0 || res.data.isPay === 7) {
            setTimeout(() => {
              this.count = this.count + 1;
              this.getPayStatus();
            }, 10000);
          } else {
            this.$router.push({
              path: "/deposit/status",
              query: {
                name: this.name,
                deptName: this.deptName,
                inDate: this.inDate,
                outDate: this.outDate,
                cost: this.cost,
                patCardNo: this.patCardNo,
                outTradeNo: this.outTradeNo
              }
            });
          }
        } else {
          this.$router.push({
            path: "/deposit/status",
            query: {
              name: this.name,
              deptName: this.deptName,
              inDate: this.inDate,
              outDate: this.outDate,
              cost: this.cost,
              patCardNo: this.patCardNo,
              outTradeNo: this.outTradeNo
            }
          });
        }
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style scope>
.pay_container {
  font-size: 100px;
  color: rgb(13, 190, 13);
  font-weight: 700;
}
.pay_container div {
  position: fixed;
  top: 30%;
  left: 53%;
  transform: translate(-50%);
}
</style>